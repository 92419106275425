div.MuiAvatar-root.ciso-advisory-council-avatar-ciso {
  height: 60px !important;
  width: 60px !important;
}

div.MuiBox-root.ciso-advisory-council-box-call-to-action {
  background-image: url(https://d3oo9a669kwmx7.cloudfront.net/backgrounds/ciso-advisory-council-background.png);
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--gray-border);
}

div.MuiBox-root.ciso-advisory-council-box-green-border {
  border-left: 1px solid var(--green);
}

p.MuiTypography-root.ciso-advisory-council-text-bold {
  font-weight: 500;
}

p.MuiTypography-root.ciso-advisory-council-text-number {
  font-size: 48px;
}
