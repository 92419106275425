a.MuiButton-root.MuiButton-text.home-button-view-all {
  margin-right: -4px;
}

a.MuiButton-root.MuiButton-text.home-button-view-all:hover {
  color: #c2bfb8;
}

a.MuiButton-root.MuiButton-text.home-button-view-all span.MuiButton-endIcon {
  margin-left: 40px;
}

div.MuiAvatar-root.home-avatar-adrian {
  height: 60px;
  width: 60px;
}

div.MuiBox-root.home-box-background-green {
  background: rgba(0, 255, 130, 0.15);
}

div.MuiBox-root.home-box-background-gray {
  background: #171615;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--gray-border);
}

div.MuiBox-root.home-box-benefit-icon {
  border-radius: var(--border-radius-small);
  background: #292929;
}

div.MuiBox-root.home-box-ciso-scroll {
  display: flex;
  overflow-x: scroll;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.home-box-ciso-content {
  animation: scroll 60s linear infinite;
}

div.MuiBox-root.home-box-ciso-scroll:hover .home-box-ciso-content {
  animation-play-state: paused;
}

div.MuiBox-root.home-box-ciso-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

div.MuiBox-root.home-box-enterprises {
  background: #0c0c0b;
  border-radius: var(--border-radius-small);
}

div.MuiBox-root.home-box-solutions-tabs {
  border: 1px solid var(--gray-border);
  border-radius: var(--border-radius-small);
  background: #141414;
}

div.MuiBox-root.home-box-top {
  background: url("https://d3oo9a669kwmx7.cloudfront.net/backgrounds/home-main-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

div.MuiCard-root.home-card-resources {
  background: #0c0c0b;
}

div.MuiCardContent-root.home-card-content-resources {
  padding: 40px;
}

div.MuiGrid-item.home-grid-item-discover-more-gif {
  background: #0c0c0b;
}

div.MuiGrid-item.home-grid-item-discover-more-text {
  background: #131312;
}

div.home-box-ecosystem-input {
  max-width: 240px;
}

@media screen and (max-width: 1250px) {
  div.home-box-ecosystem-input {
    max-width: 90%;
    margin: 10px 0;
  }
}

button.MuiTab-root.home-tab {
  border-radius: var(--border-radius-small);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  justify-content: flex-start;
  line-height: normal;
  text-align: left;
  min-height: 0;
  padding: 10px;
  text-transform: none;
  width: 100%;
  margin-right: 12px;
  padding-right: 15px;
}

button.MuiTab-root.home-tab:not(:first-child) {
  margin-top: 10px;
  margin-right: 12px;
  padding-right: 15px;
}

button.MuiTab-root.home-tab:hover {
  background: rgba(194, 191, 184, 0.1);
  margin-right: 12px;
  padding-right: 15px;
}

button.MuiTab-root.home-tab.Mui-selected {
  background: rgba(194, 191, 184, 0.1);
  font-weight: 500;
}

div.MuiTabs-root.home-tabs span.MuiTabs-indicator {
  display: none;
}

h1.MuiTypography-root.home-text-header-title {
  font-weight: 500;
  line-height: 120%;
}

h2.MuiTypography-root.home-text-header-secondary {
  font-weight: 400;
  line-height: 120%;
}

h3.MuiTypography-root.home-text-header-tertiary {
  font-weight: 500;
  line-height: 120%;
}

h4.MuiTypography-root.home-text-header-connect {
  font-size: 32px;
}

.MuiBox-root.home-image-dashboard-walkthrough {
  border-radius: var(--border-radius-small);
  position: relative;
  /* Ensure this is here if not set in JSX */
}

img.MuiBox-root.home-image-border-radius {
  border-radius: var(--border-radius-small);
}

img.MuiBox-root.home-image-ai-point {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

p.MuiTypography-root.home-text-adrian-bold {
  font-weight: 500;
}

p.MuiTypography-root.home-text-ciso-advisory {
  color: rgba(255, 255, 255);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 9.6px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
}

p.MuiTypography-root.home-text-resources-secondary {
  font-size: 16px;
}

p.MuiTypography-root.home-text-resources-title {
  font-weight: 500;
}

span.MuiTypography-root.home-text-category-solution-name {
  color: var(--white);
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

span.MuiTypography-root.home-text-header-title-italic {
  color: var(--green);
  font-family: var(--font-family-italic);
  font-size: inherit;
  font-style: italic;
  font-weight: 400;
  line-height: 120%;
}

svg.MuiSvgIcon-root.home-icon-benefit {
  color: var(--green);
  height: 160px;
  width: 160px;
}

svg.MuiSvgIcon-root.home-icon-ecosystem {
  color: var(--green);
  height: 32px;
  width: 32px;
}

.line {
  /* Adjust the color as necessary */
  width: 2px;
  /* Adjust the thickness as necessary */
  height: 60px;
  /* Adjust the length as necessary */
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

@keyframes animate-gradient {
  0% {
    stopcolor: rgba(194, 191, 184, 0.35);
  }

  50% {
    stopcolor: #00ff82;
  }

  100% {
    stopcolor: rgba(194, 191, 184, 0.35);
  }
}

/* .animated-line {
    stroke: url(#electric-gradient);
    strokeWidth: 5;
}

#electric-gradient stop {
    stopColor: rgba(194, 191, 184, 0.35);
    animation: animate-gradient 2s infinite;
} */

/* div.MuiBox-root.home-box-arrows g path {
    stroke: pink;
} */
