@keyframes confetti-shower {
  0% {
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(50vh) rotate(360deg) scale(1.05); /* Mid-point smoothness */
    opacity: 0.9;
  }
  100% {
    transform: translateY(100vh) rotate(720deg) scale(1);
    opacity: 0;
  }
}

.confetti-piece {
  position: absolute;
  pointer-events: none;
  will-change: transform, opacity;
  transition: transform 0.2s ease-out;
}

.circle {
  border-radius: 50%;
}

.square {
  border-radius: 5%;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid transparent; /* Default, overridden inline */
}

.star {
  clippath: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.diamond {
  clippath: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.hexagon {
  clippath: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.pentagon {
  clippath: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.heart {
  clippath: polygon(
    50% 90%,
    20% 60%,
    0% 35%,
    25% 0%,
    50% 20%,
    75% 0%,
    100% 35%,
    80% 60%
  );
}

.parallelogram {
  clippath: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
}

.oval {
  border-radius: 50% / 30%;
}
