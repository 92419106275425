button.MuiButton-root.compliance-onboarding-button-compliance {
    justify-content: flex-start !important;
}

button.MuiButton-root.compliance-onboarding-button-compliance:not(:first-child) {
    margin-top: 20px !important;
}

button.MuiButton-root.MuiButton-contained.dune-button-contained-gray.compliance-onboarding-button-compliance-selected {
    border-color: var(--green) !important;
}

button.MuiButton-root.compliance-onboarding-button-save {
    margin-top: 40px !important;
    max-width: 300px !important;
}

div.MuiFormGroup-root.compliance-onboarding-form-group {
    width: 50% !important;
}

@media screen and (max-width: 600px) {
    div.MuiFormGroup-root.compliance-onboarding-form-group {
        width: 100% !important;
    }
}

div.MuiInputBase-root.compliance-select {
    max-width: 420px !important;
}

ul.MuiList-root:has(li.MuiMenuItem-root.compliance-select-menu-item) {
    background: var(--background-gray) !important;
}

ul.MuiList-root li.MuiMenuItem-root.compliance-select-menu-item {
    color: var(--white) !important;
    font-family: var(--font-family) !important;
    font-size: var(--font-size-body) !important;
    padding: 10px 24px !important;
}