a.MuiButton-root.MuiButton-text.dune-button-text-white.resource-button-resource-hub {
  font-size: 16px !important;
  margin-left: -8px;
}

a.MuiButton-root.MuiButton-text.resource-button-resource-hub
  span.MuiButton-startIcon {
  margin-right: 40px;
}

a.MuiButton-root.MuiButton-text.resource-button-view-all {
  margin-right: -4px;
}

a.MuiButton-root.MuiButton-text.resource-button-view-all:hover {
  background: none;
  color: #c2bfb8 !important;
}

a.MuiButton-root.MuiButton-text.resource-button-view-all
  span.MuiButton-endIcon {
  margin-left: 40px;
}

div.MuiBox-root.resource-box-body-content a {
  color: #00c866;
}

div.MuiBox-root.resource-box-body-content a:hover {
  color: var(--green) !important;
}

div.MuiBox-root.resource-box-body-content b {
  font-weight: 500;
}

div.MuiBox-root.resource-box-body-content h3 {
  font-size: 28px;
  font-weight: 400;
}

div.MuiBox-root.resource-box-body-content hr {
  border-color: #232221;
  border-width: 1px;
  border-bottom: none;
  margin-top: 40px;
}

div.MuiBox-root.resource-box-body-content li {
  margin-top: 4px;
}

div.MuiBox-root.resource-box-body-content li p {
  margin: 4px;
}

div.MuiBox-root.resource-box-body-content ol,
div.MuiBox-root.resource-box-body-content ul {
  font-size: 20px;
  margin: 28px 0 0;
  padding: 0 0 0 40px;
}

div.MuiBox-root.resource-box-body-content ol:has(ul) > li {
  margin: 28px 0 0;
}

div.MuiBox-root.resource-box-body-content p {
  font-size: 20px;
  margin: 28px 0 0;
}

div.MuiBox-root.resource-box-main-content {
  background: transparent !important;
  color: var(--white) !important;
}

div.MuiBox-root.resource-box-main-content p.MuiTypography-root,
div.MuiBox-root.resource-box-main-content h2.MuiTypography-root,
div.MuiBox-root.resource-box-main-content h3.MuiTypography-root,
div.MuiBox-root.resource-box-main-content h4.MuiTypography-root,
div.MuiBox-root.resource-box-main-content h5.MuiTypography-root {
  color: var(--white) !important;
}

h1.MuiTypography-root.resource-text-header-main {
  color: var(--white);
}

h2.MuiTypography-root.resource-text-header-secondary {
  font-size: 28px;
}

h3.MuiTypography-root.resource-text-header-3 {
  font-size: 28px;
}

h4.MuiTypography-root.resource-text-header-4 {
  font-size: 20px;
  font-weight: 500 !important;
}

hr.MuiDivider-root.resource-divider {
  border-color: #232221;

  margin-top: 40px;
}

li.resource-list-item {
  margin-top: 4px;
}

p.MuiTypography-root.resource-text-small {
  font-size: 14px !important;
}

span.MuiTypography-root.resource-text-bold {
  color: var(--white);
  font-size: inherit;
  font-weight: 500 !important;
}

svg.MuiSvgIcon-root.resource-icon-info {
  height: 20px;
  width: 20px;
  color: var(--white);
}

svg.MuiSvgIcon-root.resource-icon-resource-hub {
  height: 20px;
  width: 20px;
}

svg.MuiSvgIcon-root.resource-icon-view-all {
  height: 20px;
  transform: rotate(180deg);
  width: 20px;
  color: white;
}
