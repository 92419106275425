div.MuiBox-root.banner-notification-box-main {
  background: var(--green);
  top: 0;
  z-index: 1000;
}

p.MuiTypography-root.banner-notification-text-description {
  color: var(--black);
  font-weight: 600;
}

p.MuiTypography-root.banner-notification-text-link {
  color: var(--black);
  font-weight: 600;
  padding-left: 20px;
}
