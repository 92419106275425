@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom classes */
.h1 {
  @apply text-h1 font-sans text-white leading-[normal];
}

.h2 {
  @apply text-h2 font-sans text-white leading-[normal];
}

.h3 {
  @apply text-h3 font-sans text-white leading-[normal];
}

.h4 {
  @apply text-h4 font-sans text-white leading-[normal];
}

.body {
  @apply text-body font-sans text-white leading-[normal];
}

.body-emphasized {
  @apply text-body-emphasized font-sans text-white leading-[normal];
}

.copyright {
  @apply text-copyright font-sans text-gray leading-[normal];
}

.description {
  @apply text-description font-sans text-white leading-[normal];
}

.description-emphasized {
  @apply text-description-emphasized font-sans text-white leading-[normal];
}

.eyebrow {
  @apply text-eyebrow font-sans text-white leading-[normal];
}

.detailed {
  @apply text-detailed font-sans text-white leading-[normal];
}

.detailed-emphasized {
  @apply text-detailed-emphasized font-sans text-white leading-[normal];
}

.card-header {
  @apply text-card-header font-sans text-white uppercase leading-[normal];
}

.card-subheader {
  @apply text-card-subheader font-sans text-gray-text-uppercase uppercase leading-[normal];
}

.spaced-header {
  @apply text-spaced-header font-sans text-white uppercase leading-[normal];
}

.table-header {
  @apply text-table-header font-sans text-white uppercase leading-[normal];
}

.note {
  @apply text-note font-sans text-white leading-[normal];
}

.onboarding-step {
  @apply text-onboarding-step font-sans text-white leading-[normal];
}

.italic-font {
  font-family: var(--font-family-italic);
}

.fixed-width {
  width: 50px; /* Adjust width as necessary */
  text-align: right; /* Ensures numbers are aligned to the right */
}

.dune-italic {
  @apply italic text-green font-dune-italic;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

/* Variables */

:root {
  /* Borders */
  --border-radius-small: 8px;
  --border-radius-large: 12px;

  /* Colors */
  --black: #000000;
  --black-text: #050505;
  --gray-light: #c2bfb8;
  --gray-medium-light: #919191;
  --gray-medium: #232221;
  --gray-dark: #0c0c0b;
  --gray-border: rgba(194, 191, 184, 0.35);
  --white: #ffffff;

  --background-main: #000000;
  --background-navigation: #000000;
  --background-gray: rgba(239, 236, 227, 0.05);
  --divider: rgba(239, 236, 227, 0.25);

  --cool-sand: #efece3;
  --deep-navy-blue: #000f21;
  --green: #00ff82;
  --red: #ff2b00;
  --secondary-blue: #00ff82;
  --gray: #131312;

  /* Font */
  --font-family: General Sans, sans-serif;
  --font-family-italic: Gambetta, General Sans, sans-serif;
  --font-size-body: 20px;
}

/* Body */

body {
  background: var(--gray-dark);
}

/* Typography */

/* Default styles for text */

h1.MuiTypography-root,
h2.MuiTypography-root,
h3.MuiTypography-root,
h4.MuiTypography-root,
h5.MuiTypography-root,
p.MuiTypography-root,
span.MuiTypography-root {
  color: var(--white);
  font-family: var(--font-family);
}

.italic-font {
  font-family: var(--font-family-italic);
}

span.MuiTypography-root.dune-text-italic {
  color: var(--green);
  font-family: var(--font-family-italic);
  font-size: inherit;
  font-style: italic;
  font-weight: inherit;
  line-height: inherit;
}

/* p.MuiTypography-root {
  font-size: var(--font-size-body);
} */

/* Website */

.dune-text-header-uppercase {
  color: var(--green);
  font-size: var(--font-size-body);
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

h1.MuiTypography-root.dune-text-header-main,
h2.MuiTypography-root.dune-text-header-main,
h3.MuiTypography-root.dune-text-header-main {
  font-size: 28px;
}

h1.MuiTypography-root.dune-text-header-main-white,
h2.MuiTypography-root.dune-text-header-main-white,
h3.MuiTypography-root.dune-text-header-main-white {
  font-size: 28px;
  color: var(--white) !important;
}

span.MuiTypography-root.dune-text-header-main-italic {
  color: var(--green);
  font-family: var(--font-family-italic);
  font-size: 34px;
  font-style: italic;
}

/* Login */

p.MuiTypography-root.dune-text-error {
  color: var(--red);
  font-size: 14px;
}

p.MuiTypography-root.dune-text-success {
  color: var(--green);
  font-size: 14px;
}

.MuiTypography-root.dune-text-small {
  font-size: 14px;
}

/* Platform */

h2.MuiTypography-root.dune-text-header-dashboard {
  font-size: var(--font-size-body);
  font-weight: 400;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

h3.MuiTypography-root.dune-text-header-card-dashboard {
  color: var(--white);
  font-size: var(--font-size-body);
}

p.MuiTypography-root.dune-text-card-dashboard-description {
  color: var(--white);
  font-size: 16px;
}

/* Alert (for snackbar's error and success messages) */

div.MuiAlert-root.dune-alert-error {
  align-items: center !important;
  background: var(--red) !important;
  display: flex !important;
}

div.MuiAlert-root.dune-alert-error div.MuiAlert-message {
  color: var(--white) !important;
  font-family: var(--font-family) !important;
  font-size: 16px !important;
}

div.MuiAlert-root.dune-alert-error svg.MuiSvgIcon-root {
  color: var(--white) !important;
  height: 24px !important;
  width: 24px !important;
}

div.MuiAlert-root.dune-alert-success {
  align-items: center !important;
  background: var(--green) !important;
  display: flex !important;
}

div.MuiAlert-root.dune-alert-success div.MuiAlert-message {
  color: var(--black) !important;
  font-family: var(--font-family) !important;
  font-size: 16px !important;
}

div.MuiAlert-root.dune-alert-success svg.MuiSvgIcon-root {
  color: var(--black) !important;
  height: 24px !important;
  width: 24px !important;
}

/* Avatar */

div.MuiAvatar-root {
  font-family: var(--font-family);
}

div.MuiAvatar-root.dune-avatar-test {
  background: #ffffff !important;
  color: #000000 !important;
  font-family: var(--font-family) !important;
  font-size: 20px !important;
  height: 40px !important;
  width: 40px !important;
}

div.MuiAvatar-root.dune-avatar-add-users {
  background: inherit !important;
  border: none !important;
  border-radius: 0 !important;
  height: 20px !important;
  margin-right: 20px !important;
  width: 20px !important;
}

/* Box */

div.MuiBox-root.dune-box-background-gray {
  background: #141414;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--gray-border);
}

div.MuiBox-root.dune-box {
  padding-top: 80px !important;
}

div.MuiBox-root.dune-box-no-data:not(:only-child) {
  margin-top: 20px !important;
}

div.MuiBox-root.dune-box-no-data p.MuiTypography-root {
  color: var(--white) !important;
  font-family: var(--font-family) !important;
  font-size: 16px !important;
}

/* Button */

a.MuiButton-root.MuiButton-contained.dune-button-contained-gray,
button.MuiButton-root.MuiButton-contained.dune-button-contained-gray {
  background: var(--gray);
  border: 1px solid var(--gray);
  border-radius: var(--border-radius-small);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}

a.MuiButton-root.MuiButton-contained.dune-button-contained-gray:hover,
button.MuiButton-root.MuiButton-contained.dune-button-contained-gray:hover {
  background: #232221;
}

/* Button (Contained, green) */

a.MuiButton-root.MuiButton-contained.dune-button-contained-green,
button.MuiButton-root.MuiButton-contained.dune-button-contained-green {
  background: var(--green);
  border-radius: var(--border-radius-small);
  box-shadow: 0px 0px 18px 0px #028334;
  color: var(--black);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  padding: 10px 20px;
  text-transform: none;
}

a.MuiButton-root.MuiButton-contained.dune-button-contained-green:hover,
button.MuiButton-root.MuiButton-contained.dune-button-contained-green:hover {
  background: #00c866;
}

a.MuiButton-root.MuiButton-contained.dune-button-contained-green:disabled,
button.MuiButton-root.MuiButton-contained.dune-button-contained-green:disabled {
  background: none;
  border: 1px solid var(--green);
  color: var(--green);
}

/* Button (Contained, white) */

a.MuiButton-root.MuiButton-contained.dune-button-contained-white,
button.MuiButton-root.MuiButton-contained.dune-button-contained-white {
  background: var(--white);
  border-radius: var(--border-radius-small);
  color: var(--black);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 4px 20px;
  text-transform: none;
}

a.MuiButton-root.MuiButton-contained.dune-button-contained-white:hover,
button.MuiButton-root.MuiButton-contained.dune-button-contained-white:hover {
  background: #c2bfb8;
}

/* Button (Outlined, gray) */

a.MuiButton-root.MuiButton-outlined.dune-button-outlined-gray,
button.MuiButton-root.MuiButton-outlined.dune-button-outlined-gray {
  border-radius: var(--border-radius-small);
  border: 1px solid rgba(194, 191, 184, 0.35);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  padding: 10px 20px;
  text-transform: none;
}

a.MuiButton-root.MuiButton-outlined.dune-button-outlined-gray:hover,
button.MuiButton-root.MuiButton-outlined.dune-button-outlined-gray:hover {
  border: 1px solid var(--cool-sand);
}

a.MuiButton-root.MuiButton-outlined.dune-button-outlined-white,
button.MuiButton-root.MuiButton-outlined.dune-button-outlined-white {
  border-radius: var(--border-radius-small);
  border: 1px solid var(--cool-sand);
  color: var(--cool-sand);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  padding: 4px 20px;
  text-transform: none;
}

a.MuiButton-root.MuiButton-outlined.dune-button-outlined-white:hover,
button.MuiButton-root.MuiButton-outlined.dune-button-outlined-white:hover {
  border-color: #c2bfb8;
  color: #c2bfb8;
}

a.MuiButton-root.MuiButton-outlined.dune-button-outlined-green,
button.MuiButton-root.MuiButton-outlined.dune-button-outlined-green {
  border-radius: var(--border-radius-small);
  border: 1px solid var(--green);
  color: var(--green);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  padding: 4px 60px;
  text-transform: none;
}

a.MuiButton-root.MuiButton-outlined.dune-button-outlined-white,
button.MuiButton-root.MuiButton-outlined.dune-button-outlined-white {
  border-radius: var(--border-radius-small);
  border: 1px solid var(--cool-sand);
  color: var(--cool-sand);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  padding: 4px 20px;
  text-transform: none;
}

a.MuiButton-root.MuiButton-outlined.dune-button-outlined-white:hover,
button.MuiButton-root.MuiButton-outlined.dune-button-outlined-white:hover {
  border-color: #c2bfb8;
  color: #c2bfb8;
}

a.MuiButton-root.MuiButton-text.dune-button-text-white,
button.MuiButton-root.MuiButton-text.dune-button-text-white {
  color: var(--white);
  font-family: var(--font-family);
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  font-size: 1rem;
}

a.MuiButton-root.MuiButton-text.dune-button-text-black,
button.MuiButton-root.MuiButton-text.dune-button-text-black {
  color: var(--black);
  font-family: var(--font-family);
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  font-size: 1rem;
}

a.MuiButton-root.MuiButton-text.dune-button-text-black:hover,
button.MuiButton-root.MuiButton-text.dune-button-text-black:hover {
  background: inherit;
  color: #c2bfb8;
}

a.MuiButton-root.MuiButton-text.dune-button-text-green,
button.MuiButton-root.MuiButton-text.dune-button-text-green {
  color: var(--green);
  font-family: var(--font-family);
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  font-size: 1rem;
}

a.MuiButton-root.MuiButton-text.dune-button-text-green:hover,
button.MuiButton-root.MuiButton-text.dune-button-text-green:hover {
  color: #00c866;
}

a.MuiButton-root.MuiButton-text.dune-button-text-white,
button.MuiButton-root.MuiButton-text.dune-button-text-white {
  color: var(--white);
  font-family: var(--font-family);
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  font-size: 1rem;
}

a.MuiButton-root.MuiButton-text.dune-button-text-white:hover,
button.MuiButton-root.MuiButton-text.dune-button-text-white:hover {
  color: #c2bfb8;
}

a.MuiButton-root.MuiButton-text span.MuiButton-endIcon svg,
button.MuiButton-root.MuiButton-text span.MuiButton-endIcon svg {
  font-size: 24px;
}

button.MuiButton-root.MuiButton-contained.dune-button-add-users,
span.MuiButton-root.MuiButton-contained.dune-button-add-users {
  background: var(--background-gray);
  border-color: var(--white);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  justify-content: flex-start;
  line-height: normal;
  padding: 16px 32px;
  text-transform: none;
}

/* Card */

div.MuiCard-root.dune-card-dashboard {
  border: 1px solid rgba(194, 191, 184, 0.35);
  background: rgba(35, 34, 33, 0.45);
  border-radius: var(--border-radius-large);
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.MuiCard-root.dune-card-test {
  background-color: var(--gray) !important;
  border-radius: var(--border-radius-large) !important;
  height: 100% !important;
}

div.MuiCardContent-root.dune-card-content-test {
  padding: 60px !important;
}

/* Checkbox */

span.MuiCheckbox-root.dune-checkbox-green {
  color: var(--white);
}

span.MuiCheckbox-root.dune-checkbox-green.Mui-checked {
  color: var(--green);
}

span.MuiCheckbox-root.dune-checkbox-white {
  color: var(--white);
}

/* Container */

div.MuiContainer-root.dune-container-no-padding {
  padding: 0;
}

/* Divider */

hr.MuiDivider-root.dune-divider {
  border-color: var(--divider) !important;
  width: 100% !important;
}

hr.MuiDivider-root.dune-divider-gray {
  border-color: #232221;
  border-width: 1.5px;
  width: 100%;
}

/* Grid */

div.MuiGrid-root.MuiGrid-container.dune-grid-container {
  height: 100% !important;
}

/* Default bottom and top padding changed to 0. */
div.MuiGrid-root.MuiGrid-item:not(.dune-grid-item-default) {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

/* Input: Text Field */

div.MuiTextField-root {
  border-radius: var(--border-radius-small);
  color: var(--white);
}

div.MuiTextField-root input,
div.MuiTextField-root label,
div.MuiTextField-root svg {
  color: var(--white);
  font-family: var(--font-family);
  font-size: var(--font-size-body);
}

/* Get rid of white background after using autofill. */
div.MuiTextField-root input:-webkit-autofill,
div.MuiTextField-root input:-webkit-autofill:hover,
div.MuiTextField-root input:-webkit-autofill:focus,
div.MuiTextField-root input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
}

div.MuiTextField-root.dune-text-field-filled {
  background: #0c0c0b;
  border: 1.5px solid #232221;
  border-radius: var(--border-radius-small);
}

div.MuiTextField-root.dune-text-field-filled:has(input:focus),
div.MuiFormControl-root.dune-text-field-filled-multiline:has(textarea:focus) {
  border-color: var(--green);
}

div.MuiTextField-root.dune-text-field-filled input,
div.MuiFormControl-root.dune-text-field-filled-multiline div.MuiInputBase-root {
  color: var(--white);
  font-family: var(--font-family);
  font-size: 16px;
  padding: 16px 20px;
}

div.MuiTextField-root.dune-text-field-filled input::placeholder,
div.MuiFormControl-root.dune-text-field-filled-multiline textarea::placeholder {
  color: #c2bfb8;
  opacity: 1;
}

div.MuiTextField-root.dune-text-field-filled label {
  font-size: 16px;
}

div.MuiTextField-root.dune-text-field-filled:has(
    div.MuiInputBase-root.Mui-error
  ) {
  border-color: var(--red);
}

div.MuiTextField-root.dune-text-field-outlined-gray {
  background: var(--gray);
  border-radius: var(--border-radius-small);
}

div.MuiTextField-root.dune-text-field-outlined-gray:disabled {
  color: var(--white);
}

/* Input: Select */

div.MuiInputBase-root.dune-select-gray {
  background: var(--gray);
  border-radius: var(--border-radius-small);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}

div.MuiInputBase-root.dune-select-gray div.MuiSelect-select {
  padding: 16px 20px;
}

div.MuiInputBase-root.dune-select-gray div.MuiSelect-select:focus {
  background: inherit;
  border-radius: inherit;
}

div.MuiInputBase-root.dune-select-gray svg.MuiSelect-icon {
  color: var(--white);
}

div.MuiInputBase-root.dune-select-white {
  background: var(--white);
  border-radius: var(--border-radius-small);
  color: var(--black);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}

div.MuiInputBase-root.dune-select-white div.MuiSelect-select {
  padding: 16px 20px;
}

div.MuiInputBase-root.dune-select-white div.MuiSelect-select:focus {
  background: inherit;
  border-radius: inherit;
}

div.MuiInputBase-root.dune-select-white svg.MuiSelect-icon {
  color: var(--black);
  height: 16px;
  margin-right: 8px;
  width: 16px;
}

/* Link */

a.dune-link-white {
  color: var(--white);
}

a.dune-link-white:hover {
  color: #c2bfb8;
}

a.dune-link {
  color: var(--green) !important;
  font-size: inherit !important;
  text-decoration: none !important;
  font-weight: 500;
}

/* Menu Item */

div.MuiPaper-root:has(li.MuiMenuItem-root.dune-menu-item) {
  background: var(--gray);
  color: var(--white);
}

li.MuiMenuItem-root.dune-menu-item {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}

li.MuiMenuItem-root.dune-menu-item.Mui-selected {
  background-color: inherit;
  font-weight: 500;
}

li.MuiMenuItem-root.dune-menu-item:hover {
  font-weight: 500;
}

span.MuiSkeleton-root.dune-skeleton-rectangular {
  background: #232221;
  border-radius: var(--border-radius-large);
  flex-grow: 1;
}

input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3Csvg width=%2218%22 height=%2218%22 viewBox=%220%200%2018%2018%22 fill=%22none%22 xmlns=%22http://www.w3.org/2000/svg%22%3E%3Cg filter=%22url(%23filter0_f_17443_31823)%22%3E%3Crect x=%224%22 y=%224%22 width=%2210%22 height=%2210%22 rx=%222%22 fill=%22%2300FF82%22/%3E%3C/g%3E%3Crect x=%224%22 y=%224%22 width=%2210%22 height=%2210%22 rx=%222%22 fill=%22%2300FF82%22/%3E%3Cpath d=%22M6.17188%208.885L7.86999%2010.625L11.408%207%22 stroke=%22%23202020%22 stroke-width=%220.690476%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3Cdefs%3E%3Cfilter id=%22filter0_f_17443_31823%22 x=%220%22 y=%220%22 width=%2218%22 height=%2218%22 filterUnits=%22userSpaceOnUse%22 color-interpolation-filters=%22sRGB%22%3E%3CfeFlood flood-opacity=%220%22 result=%22BackgroundImageFix%22/%3E%3CfeBlend mode=%22normal%22 in=%22SourceGraphic%22 in2=%22BackgroundImageFix%22 result=%22shape%22/%3E%3CfeGaussianBlur stdDeviation=%222%22 result=%22effect1_foregroundBlur_17443_31823%22/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  background-size: 200% 200%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #00ff82; /* To match the rectangle fill color */
  border: none; /* Remove border, as the SVG handles that */
}
