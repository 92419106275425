a.MuiLink-root.resources-group-link {
  text-decoration: none;
}

div.MuiBox-root.resources-group-box-image {
  border-radius: var(--border-radius-small);
}

div.MuiCard-root.resources-group-card {
  background: "#0D0D0B" !important;
  border-radius: 0 !important;
  box-shadow: none;
}

div.MuiCard-root.resources-group-card.resources-group-card-light {
  background: var(--white) !important;
}

div.MuiCard-root.resources-group-card.resources-group-card-dark {
  background: transparent !important;
}

div.MuiCard-root.resources-group-card:hover
  img.MuiCardMedia-root.resources-group-card-media {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

div.MuiCard-root.resources-group-card:hover p.MuiTypography-root {
  color: #c2bfb8 !important;
}

div.MuiCardContent-root.resources-group-card-content {
  padding: 40px 0 0 !important;
}

p.MuiTypography-root.resources-group-text-date {
  color: var(--white);
  font-size: 14px !important;
}

p.MuiTypography-root.resources-group-text-title {
  color: var(--white);
  font-weight: 500 !important;
}
