a.footer-button-main-page {
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

a.footer-button-main-page:hover {
    color: #C2BFB8;
}

a.footer-button-subpage {
    color: var(--white);
    font-size: 16px;
    margin-top: 20px;
    text-decoration: none;
}

a.footer-button-subpage:hover {
    color: #C2BFB8;
}

a.MuiIconButton-root.footer-icon-button {
    color: var(--white);
    padding: 0;
}

a.MuiIconButton-root.footer-icon-button:hover {
    color: #C2BFB8 !important;
}

a.MuiIconButton-root.footer-icon-button:not(:first-child) {
    margin-left: 20px;
}

div.MuiBox-root.footer-box-main {
    border-radius: var(--border-radius-small);
    background: rgba(35, 34, 33, 0.35);
    box-shadow: 0px 0px 20px 0px rgba(19, 19, 19, 0.20);
}

p.MuiTypography-root.footer-text-copyright {
    font-size: 14px;
}

p.MuiTypography-root.footer-text-slogan {
    font-size: 16px;
}

span.MuiTypography-root.footer-text-powered-by-ai {
    color: var(--green);
    font-family: var(--font-family-italic);
    font-size: inherit;
    font-style: italic;
    font-weight: 400;
}