.security-and-compliance-link-email {
    color: var(--white);
    text-decoration: none;
}

.security-and-compliance-link-email:hover {
    color: #C2BFB8;
}

.security-and-compliance-box-white-border {
    background: var(--black);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--white);
}

.security-and-compliance-box-black-border {
    border-radius: var(--border-radius-small);
    border: 1px solid var(--black);
}

.security-and-compliance-list-item-text {
    margin: 0;
}

.security-and-compliance-list-item-text span.MuiTypography-root {
    font-size: 1rem;
}

.security-and-compliance-text-heading,
.security-and-compliance-text-heading {
    font-size: 28px;
}

.security-and-compliance-list-item {
    display: list-item;
}

.security-and-compliance-list {
    color: var(--white);
    list-style-type: disc;
    padding-left: 18px;
}