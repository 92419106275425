/* Custom checkbox styling */
.custom-checkbox {
  appearance: none;
  width: 1em;
  height: 1em;
  border: 1px solid #c2bfb8;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-right: 0.5rem;
  background-color: black;
}

.custom-checkbox:checked {
  background-color: #00ff82; /* Green background for checked state */
  border-color: #00ff82;
}

.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  left: 0.25em;
  width: 0.5em;
  height: 0.9em;
  transform: rotate(45deg);
}
