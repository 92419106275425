a.MuiButton-root.MuiButton-text.dune-button-text-white.resource-button-resource-hub {
  font-size: 16px !important;
  margin-left: -8px;
}

a.MuiButton-root.MuiButton-text.resource-button-resource-hub
  span.MuiButton-startIcon {
  margin-right: 40px;
}

a.MuiButton-root.MuiButton-text.resource-button-view-all {
  margin-right: -4px;
}

a.MuiButton-root.MuiButton-text.resource-button-view-all:hover {
  background: none;
  color: #c2bfb8 !important;
}

a.MuiButton-root.MuiButton-text.resource-button-view-all
  span.MuiButton-endIcon {
  margin-left: 40px;
}

a.resource-webinar-recap-blog-link {
  color: #00c866;
}

a.resource-webinar-recap-blog-link:hover {
  color: var(--green);
}

div.MuiBox-root.resource-webinar-recap-blog-box-main-content {
  background: transparent;
}

div.MuiBox-root.resource-webinar-recap-blog-box-main-content
  p.MuiTypography-root,
div.MuiBox-root.resource-webinar-recap-blog-box-main-content
  h2.MuiTypography-root,
div.MuiBox-root.resource-webinar-recap-blog-box-main-content
  h3.MuiTypography-root,
div.MuiBox-root.resource-webinar-recap-blog-box-main-content
  h4.MuiTypography-root,
div.MuiBox-root.resource-webinar-recap-blog-box-main-content
  h5.MuiTypography-root,
div.MuiBox-root.resource-webinar-recap-blog-box-main-content
  span.MuiTypography-root {
  color: var(--white) !important;
}

h1.MuiTypography-root.resource-text-header-main {
  color: var(--black);
}

h2.MuiTypography-root.resource-webinar-recap-blog-text-heading-2 {
  font-size: 28px;
}

h3.MuiTypography-root.resource-webinar-recap-blog-text-header-3 {
  font-size: 20px;
  font-weight: 500 !important;
}

hr.MuiDivider-root.resource-divider {
  border-color: #232221;
  margin-top: 40px;
}

img.MuiBox-root.resource-webinar-recap-blog-image-thumbnail {
  border-radius: var(--border-radius-large);
}

li.resource-webinar-recap-blog-list-item {
  margin-top: 4px;
}

ol.resource-webinar-recap-blog-ordered-list,
ul.resource-webinar-recap-blog-unordered-list {
  font-size: 20px;
  margin: 0;
}

p.MuiTypography-root.resource-text-small {
  font-size: 14px !important;
}

span.MuiTypography-root.resource-webinar-recap-blog-text-bold {
  font-size: inherit;
  font-weight: 500 !important;
}

svg.MuiSvgIcon-root.resource-icon-info {
  height: 20px;
  width: 20px;
}

svg.MuiSvgIcon-root.resource-icon-resource-hub {
  height: 20px;
  width: 20px;
}

svg.MuiSvgIcon-root.resource-icon-view-all {
  height: 20px;
  transform: rotate(180deg);
  width: 20px;
}
