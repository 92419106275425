div.MuiBox-root.fade-in-section {
    opacity: 0;
    transform: translateY(80px);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}

div.MuiBox-root.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}