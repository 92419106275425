a.users-table-link-name {
    text-decoration: none;
}

a.users-table-link-name:hover p.MuiTypography-root {
    color: #C2BFB8;
}

button.dune-button-text-white.users-table-button-remove {
    margin-right: -6px;
    padding: 4px;
}

button.dune-button-text-white.users-table-button-select-all {
    font-weight: 500 !important;
    padding: 0;
    text-decoration: underline;
}

div.user-box-text-field {
    padding-top: 40px;
}

div.user-box-text-field input {
    width: 100%;
}

div.user-box-table div.user-box-table-top {
    background: var(--background-gray);
    border-top-left-radius: var(--border-radius-small);
    border-top-right-radius: var(--border-radius-small);
    height: 40px;
}

div.MuiBox-root.user-box-table th.MuiTableCell-root div.MuiBox-root.user-box-table td.MuiTableCell-root,
div.MuiBox-root.user-box-table td.MuiTableCell-root div.MuiBox-root,
div.MuiBox-root.user-box-table td.MuiTableCell-root p.MuiTypography-root {
    font-size: 16px;
}

div.MuiBox-root.users-table-box-bottom {
    background: #131312;
    border: 1.5px solid #232221;
    border-radius: 0 0 8px 8px;
    height: 48px;
}

div.MuiBox-root.users-table-cell-box-risk-score-status {
    /* Default background and text color - risk score between 1 and 20 */
    border: 1px solid var(--green);
    border-radius: var(--border-radius-small);
    color: var(--green);

    padding: 0;
    text-align: center;
    width: 36px;
}

div.MuiBox-root.users-table-cell-box-risk-score-status-default {
    border-radius: var(--border-radius-small);
    border: 1px solid var(--white);
    color: var(--white);
    padding: 0;
    text-align: center;
    width: 36px;
}

div.MuiBox-root.users-table-cell-box-risk-score-status.users-table-cell-box-risk-score-status-moderate {
    border-color: #FDFF00;
    color: #FDFF00;
}

div.MuiBox-root.users-table-cell-box-risk-score-status.users-table-cell-box-risk-score-status-elevated {
    border-color: #FFA100;
    color: #FFA100;
}

div.MuiBox-root.users-table-cell-box-risk-score-status.users-table-cell-box-risk-score-status-severe {
    border-color: #FF2B00;
    color: #FF2B00;
}

div.MuiBox-root.users-table-box-select-all {
    margin-top: 32px;
    position: absolute;
    z-index: 1;
}

div.MuiBox-root.users-table-box-sticky-toolbar {
    background: var(--gray-dark);
    position: sticky;
    z-index: 1;
}

li.MuiMenuItem-root.dune-menu-item.users-table-pagination-menu-item {
    font-size: 16px;
}

p.MuiTypography-root.users-table-text-selected {
    font-size: 16px;
}

span.MuiCheckbox-root.users-table-checkbox-select {
    padding: 0;
}

svg.MuiSvgIcon-root.users-table-icon-check-box-outlined {
    color: var(--white);
    height: 16px;
    width: 16px;
}

svg.MuiSvgIcon-root.users-table-icon-search {
    height: 24px;
    width: 24px;
}

svg.MuiSvgIcon-root.users-table-icon-remove {
    margin-left: 20px;
}

/* Users table */

div.MuiTableContainer-root.users-table-table-container {
    border-radius: 8px;
}

table.MuiTable-root.users-table-table tbody.MuiTableBody-root {
    background: #0C0C0B;
}

table.MuiTable-root.users-table-table td.MuiTableCell-root,
table.MuiTable-root.users-table-table th.MuiTableCell-root {
    color: var(--white);
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
}

table.MuiTable-root.users-table-table td.MuiTableCell-root:not(:first-child):not(:last-child),
table.MuiTable-root.users-table-table th.MuiTableCell-root:not(:first-child):not(:last-child) {
    padding: 12px;
}

table.MuiTable-root.users-table-table td.MuiTableCell-root:first-child,
table.MuiTable-root.users-table-table th.MuiTableCell-root:first-child {
    padding: 12px 12px 12px 24px;
}

table.MuiTable-root.users-table-table td.MuiTableCell-root:last-child,
table.MuiTable-root.users-table-table th.MuiTableCell-root:last-child {
    padding: 12px 24px 12px 12px;
}

table.MuiTable-root.users-table-table tr.MuiTableRow-root:not(:last-child) td.MuiTableCell-root,
table.MuiTable-root.users-table-table tr.MuiTableRow-root th.MuiTableCell-root {
    border-bottom: 1.5px solid #232221;
}

table.MuiTable-root.users-table-table tr.MuiTableRow-root:last-child td.MuiTableCell-root {
    border-bottom: none;
}

table.MuiTable-root.users-table-table span.MuiTableSortLabel-root:not(.Mui-active) svg.MuiTableSortLabel-icon {
    color: #C2BFB8 !important;
    opacity: 1;
}

table.MuiTable-root.users-table-table span.MuiTableSortLabel-root:hover,
table.MuiTable-root.users-table-table span.MuiTableSortLabel-root:hover svg.MuiTableSortLabel-icon {
    color: #C2BFB8 !important;
}

table.MuiTable-root.users-table-table span.MuiTableSortLabel-root:hover,
table.MuiTable-root.users-table-table span.MuiTableSortLabel-root.Mui-active {
    color: inherit;
}

table.MuiTable-root.users-table-table svg.MuiTableSortLabel-icon {
    color: var(--white);
}

td.MuiTableCell-root.users-table-text-compliant-no {
    color: var(--red);
}

td.MuiTableCell-root.users-table-text-compliant-yes {
    color: var(--green);
}

tr.MuiTableRow-root.users-table-table-row-headers {
    background: #131312;
    border: 1.5px solid #232221;
    border-radius: 8px;
}

/* Table pagination */

div.MuiTablePagination-root.users-table-pagination div.MuiInputBase-root {
    background: var(--gray-dark);
    border-radius: var(--border-radius-small);
    color: var(--white);
    font-family: var(--font-family);
    font-size: 16px;
    padding: 0 4px;
    margin: 0 40px;
}

div.MuiTablePagination-root.users-table-pagination div.MuiInputBase-root svg {
    color: var(--white);
}

div.MuiTablePagination-root.users-table-pagination div.MuiToolbar-root {
    padding: 0;
}

div.MuiTablePagination-root.users-table-pagination div.MuiTablePagination-actions button svg {
    color: var(--white);
    height: 36px;
    width: 36px;
}

div.MuiTablePagination-root.users-table-pagination div.MuiTablePagination-actions button svg:hover {
    color: #C2BFB8;
}

div.MuiTablePagination-root.users-table-pagination div.MuiTablePagination-actions button:disabled svg {
    color: #C2BFB8;
}

div.MuiTablePagination-root.users-table-pagination p {
    color: var(--white);
    font-family: var(--font-family);
    font-size: 16px;
}