a.MuiButton-root.MuiButton-contained.company-button-cta {
    max-width: 178px;
}

a.MuiIconButton-root.company-icon-button {
    color: var(--white);
    padding: 0;
}

a.MuiIconButton-root.company-icon-button:not(:first-child) {
    margin-left: 20px;
}

a.MuiIconButton-root.company-icon-button:hover {
    color: #C2BFB8 !important;
}

div.MuiBox-root.company-box-image-leader {
    border-radius: var(--border-radius-small);
}

div.MuiBox-root.company-box-mission {
    background: #0C0C0B;
}

img.MuiBox-root.company-image-leader {
    border-radius: var(--border-radius-small);
    width: 100%;
}

img.MuiBox-root.company-image-team {
    border-radius: var(--border-radius-small) !important;
}

p.MuiTypography-root.company-text-name {
    font-weight: 500;
}

span.MuiTypography-root.company-text-mission-italic {
    color: var(--green) !important;
    font-family: var(--font-family-italic) !important;
    font-size: 22px;
    font-style: italic;
}