div.MuiBox-root.navigation-platform-top-box {
    background: var(--gray-dark);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
}

p.MuiTypography-root.navigation-platform-top-text-viewing-organization {
    font-size: 16px;
}

svg.MuiSvgIcon-root.navigation-platform-top-icon-arrow-back {
    color: var(--white);
    font-size: 18px;
    transform: rotate(-90deg);
}

/* Avatar for user grid item */

div.MuiAvatar-root.navigation-platform-top-avatar-initials {
    background: var(--white);
    color: var(--black);
}

div.MuiAvatar-root.navigation-platform-top-avatar-feedback {
    cursor: pointer;
}

div.MuiAvatar-root.navigation-platform-top-avatar-gray {
    background: #181817;
    color: var(--white);
    margin-right: 20px;
}

div.MuiAvatar-root.navigation-platform-top-avatar-gray svg {
    font-size: 18px;
}

/* Popover: User */

button.MuiButton-root.navigation-platform-top-button-company-view {
    font-size: 20px !important;
    justify-content: flex-start;
    margin-left: -4px;
    padding: 10px 4px;
}

button.MuiButton-root.navigation-platform-top-button-log-out {
    font-size: 20px !important;
    justify-content: flex-start;
    padding: 10px 4px;
}

button.MuiButton-root.navigation-platform-top-button-log-out span.MuiButton-startIcon {
    margin: 0 20px 0 0;
}

div.MuiPopover-root.navigation-platform-top-popover-user div.MuiPaper-root {
    background: #131312;
    max-width: 360px;
    padding: 20px 20px 10px;
    width: 100%;
}

p.MuiTypography-root.navigation-platform-top-text-bold {
    font-weight: 500;
}

svg.MuiSvgIcon-root.navigation-platform-top-icon-log-out {
    height: 24px;
    width: 24px;
}

/* Popover: Feedback */

div.MuiPopover-root.navigation-platform-top-popover-feedback div.MuiPaper-root {
    background: #131312 !important;
    color: var(--white) !important;
    padding: 20px !important;
    max-width: 350px !important;
    width: 100% !important;
}

div.MuiTextField-root.dune-text-field-filled.navigation-platform-top-text-field-feedback {
    border: 1px solid var(--white) !important;
}

p.MuiTypography-root.navigation-platform-top-text-popover {
    font-size: 16px !important;
}