div.MuiAvatar-root.testimonials-avatar-ciso {
    height: 60px !important;
    width: 60px !important;
}

div.MuiBox-root.testimonials-box-gray {
    background: #0C0C0B;
}

p.MuiTypography-root.testimonials-text-bold {
    font-weight: 500;
}