button.MuiIconButton-root.training-video-icon-button-play {
    background: rgba(239, 236, 227, 0.75);
}

div.MuiBox-root.training-video-box-thumbnail-background {
    border-radius: var(--border-radius-small);
}

div.MuiBox-root.training-video-box-thumbnail-main:hover div.MuiBox-root.training-video-box-thumbnail-background {
    background: rgba(239, 236, 227, 0.25);
}