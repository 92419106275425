div.login-box-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://d3oo9a669kwmx7.cloudfront.net/backgrounds/login-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: -1;
}
