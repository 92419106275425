a.MuiButton-root.navigation-website-button-link {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px !important;
}

a.navigation-website-link {
    color: var(--white);
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
}

a.navigation-website-link-subpage {
    color: var(--white);
    font-size: 16px;
    text-decoration: none;
}

a.navigation-website-link-subpage:has(li.MuiListItem-root:hover) {
    color: #C2BFB8;
}

a.navigation-website-link-mobile {
    text-decoration: none;
}

a.navigation-website-link-mobile p.MuiTypography-root {
    font-size: 16px !important;
    text-decoration: none;
}

a.navigation-website-link:hover,
a.navigation-website-link-mobile:hover p.MuiTypography-root {
    color: #C2BFB8 !important;
}

button.MuiIconButton-root.navigation-website-icon-button-menu {
    border-radius: var(--border-radius-small);
    padding: 8px;
}

div.MuiBox-root.navigation-website-box-main {
    background: rgba(12, 12, 11, 0.90);
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
}

div.MuiPopover-root.navigation-website-menu div.MuiPaper-root {
    background: #131312;
    width: 100%;
}

div.MuiPopover-root.navigation-website-menu ul.MuiList-root {
    padding: 0;
}

div.MuiToolbar-root.navigation-website-toolbar {
    height: 100%;
}

header.MuiAppBar-root.navigation-website-app-bar {
    background: inherit;
    box-shadow: none;
    height: 84px;
    top: 69px;
}

li.MuiMenuItem-root.navigation-website-menu-item {
    justify-content: center !important;
    padding: 8px !important;
}

/* Popover: Desktop */

button.MuiTab-root.navigation-website-tab {
    color: var(--white);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    min-height: 0;
    padding: 20px 0;
    text-transform: none;
    width: 100%;
}

button.MuiTab-root.navigation-website-tab.Mui-selected {
    color: var(--green);
    font-weight: 500;
}

div.MuiBox-root.navigation-website-box-subpage-name {
    font-weight: 500;
}

div.MuiBox-root.navigation-website-box-desktop-popover {
    background: var(--black);
    border-bottom-left-radius: var(--border-radius-small);
    border-bottom-right-radius: var(--border-radius-small);
    position: fixed;
    right: 0;
    width: fit-content;
    z-index: 1100;
}

svg.MuiSvgIcon-root.navigation-website-icon-menu {
    color: var(--green);
}