a.logo-item-link {
    text-decoration: none;
}

img.MuiBox-root.logo-item-image {
    height: 32px;
}

@media screen and (max-width: 600px) {
    img.MuiBox-root.logo-item-image {
        height: 24px;
    }
}