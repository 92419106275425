button.MuiButton-root.sso-settings-button-connection-choice {
    height: 100%;
    justify-content: flex-start;
    padding: 20px;
    text-align: left;
}

button.MuiButton-root.sso-settings-button-get-new-token {
    max-width: 160px;
    padding: 12px 20px !important;
}

button.sso-settings-button-connection-choice-selected {
    border: 1.5px solid var(--green) !important;
}

div.MuiAutocomplete-root.sso-settings-autocomplete-domains input {
    padding: 16px 20px !important;
}

div.MuiAutocomplete-root.sso-settings-autocomplete-domains div.MuiFilledInput-root {
    padding: 0;
}

div.MuiAutocomplete-root.sso-settings-autocomplete-domains div.MuiInputBase-root:after {
    border-bottom: none;
}

div.MuiAutocomplete-root.sso-settings-autocomplete-domains div.MuiChip-root {
    color: var(--white);
    font-family: var(--font-family);
    font-size: 16px;
}

div.MuiAutocomplete-root.sso-settings-autocomplete-domains div.MuiChip-root:first-child {
    margin: 0 0 0 20px;
}

div.MuiAutocomplete-root.sso-settings-autocomplete-domains div.MuiChip-root:not(:first-child) {
    margin: 0 0 0 4px;
}

div.MuiAutocomplete-root.sso-settings-autocomplete-domains svg.MuiSvgIcon-root.MuiChip-deleteIcon {
    color: var(--white);
    height: 16px;
    width: 16px;
}

div.MuiBox-root.sso-settings-box-loading {
    border: 1.5px solid var(--white);
    border-radius: var(--border-radius-small);
}

p.MuiTypography-root.sso-settings-text-error {
    color: var(--red);
    font-size: 14px;
}

p.MuiTypography-root.sso-settings-text-success {
    color: var(--green);
    font-size: 14px;
}

p.MuiTypography-root.sso-settings-text-label {
    font-size: 14px;
}

span.MuiCircularProgress-root.sso-settings-circular-progress {
    color: var(--green);
}

svg.MuiSvgIcon-root.sso-settings-icon-copy {
    color: var(--white);
}

svg.MuiSvgIcon-root.sso-settings-icon-copy:hover {
    color: #C2BFB8;
}