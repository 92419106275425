div.ecosystem-diagram-background-inherit {
  background: var(--gray-dark);
  max-width: 144px;
  width: 100%;
}

div.MuiBox-root.ecosystem-diagram-box-background-gray {
  background: #191919;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--gray-border);
}

svg.MuiSvgIcon-root.ecosystem-diagram-icon-green {
  color: var(--green);
  height: 20px;
  width: 20px;
}
