div.MuiAccordion-root.accordions-accordion {
    background: var(--black);
    border-bottom: 1px solid #232221;
    color: var(--white);
    font-size: 20px;
}

div.MuiAccordion-root.accordions-accordion:not(:first-child) div.MuiAccordionSummary-root.accordions-accordion-summary {
    padding-top: 20px;
}

div.MuiAccordionDetails-root.accordions-accordion-details {
    padding: 0 28px 20px 0;
}

div.MuiAccordionSummary-root.accordions-accordion-summary {
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    min-height: 0;
}


div.MuiAccordionSummary-root.accordions-accordion-summary div.MuiAccordionSummary-content {
    margin: 0;
}

svg.MuiSvgIcon-root.accordions-icon-expand-more {
    color: var(--white);
    height: 32px !important;
    width: 32px !important;
}

/* Expanded styles */

div.MuiAccordion-root.Mui-expanded.accordions-accordion {
    margin: 0;
}

div.MuiAccordionSummary-root.Mui-expanded.accordions-accordion-summary {
    color: var(--green);
}

div.MuiAccordionSummary-root.Mui-expanded.accordions-accordion-summary svg.MuiSvgIcon-root.accordions-icon-expand-more {
    color: var(--green);
}