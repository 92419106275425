button.MuiButton-root.campaigns-button-launch {
    margin-top: 80px !important;
    max-width: 300px !important;
}

div.MuiBox-root.campaigns-box-text-field:not(:first-child) {
    margin-top: 40px !important;
}

div.MuiBox-root.campaigns-box-timer-number {
    width: 120px !important;
}

div.MuiTextField-root.campaigns-text-field {
    margin-top: 20px !important;
}

div.MuiTextField-root.campaigns-text-field input {
    font-size: 16px !important;
    padding: 16px 20px !important;
    -webkit-text-fill-color: inherit !important;
}

p.MuiTypography-root.campaigns-text-email,
p.MuiTypography-root.campaigns-text-warning {
    font-size: 16px !important;
}

span.MuiTypography-root.campaigns-text-timer-number {
    font-size: 96px !important;
}