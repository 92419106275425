button.MuiButton-root.welcome-button {
    display: block !important;
    padding: 40px !important;
    text-align: left !important;
}

button.MuiButton-root.welcome-button:not(:first-child) {
    margin-top: 20px !important;
}

div.MuiBox-root.welcome-box-step-number {
    margin-right: 40px !important;
    width: 28px !important;
}

h3.MuiTypography-root.welcome-text-header-step {
    font-size: 20px !important;
    font-weight: 500 !important;
}

p.MuiTypography-root.welcome-text-step-number {
    font-size: 48px !important;
}