div.MuiCard-root.error-card {
    background: #0C0C0B;
    max-height: 360px !important;
    max-width: 500px !important;
}

div.MuiCardContent-root.error-card-content {
    padding: 52px !important;
}

h1.MuiTypography-root.error-text-header {
    font-family: Gambetta !important;
    font-size: 64px !important;
    font-style: italic !important;
}

svg.MuiSvgIcon-root.error-icon {
    color: var(--red) !important;
}