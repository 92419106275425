button.MuiButton-root.organization-members-button,
span.MuiButton-root.organization-members-button {
    background: var(--background-gray) !important;
    border-color: var(--white) !important;
    color: var(--white) !important;
    font-family: var(--font-family) !important;
    font-size: var(--font-size-body) !important;
    font-weight: 400 !important;
    justify-content: flex-start !important;
    padding: 16px 32px !important;
    text-transform: none !important;
}

button.MuiButton-root.MuiButton-contained.organization-members-button-add-users {
    margin-top: 40px !important;
    max-width: 200px !important;
}

button.MuiButton-root.employees-button-save {
    margin-top: 40px !important;
    max-width: 300px !important;
}

button.MuiButton-root.organization-users-button-upload-users-okta {
    margin-top: 20px !important;
}

div.MuiAvatar-root.organization-users-avatar {
    background: inherit !important;
    border: none !important;
    border-radius: 0 !important;
    height: 20px !important;
    margin-right: 20px !important;
    width: 20px !important;
}

div.MuiDialog-root.organization-members-dialog div.MuiDialog-paper {
    background: var(--black) !important;
    padding: 20px !important;
}

div.MuiTextField-root.organization-users-text-field-okta {
    margin-top: 20px !important;
}

svg.MuiSvgIcon-root.organizations-members-icon-upload {
    height: 20px !important;
    margin-right: 20px !important;
    width: 20px !important;
}