div.MuiBox-root.privacy-policy-box-body-content a {
  color: var(--green);
}

div.MuiBox-root.privacy-policy-box-body-content b {
  color: var(--white);
  font-weight: 500;
}

div.MuiBox-root.privacy-policy-box-body-content h2 {
  color: var(--white);
  line-height: normal;
  font-size: 2rem;
  font-weight: 500;
}

div.MuiBox-root.privacy-policy-box-body-content h3 {
  color: var(--white);
  line-height: normal;
  font-size: 1.625rem;
  font-weight: 500;
}

div.MuiBox-root.privacy-policy-box-body-content h4 {
  color: var(--white);
  line-height: normal;
  font-size: 1.125rem;
  font-weight: 500;
}

div.MuiBox-root.privacy-policy-box-body-content ul {
  color: var(--gray-light);
}

div.MuiBox-root.privacy-policy-box-body-content p {
  color: var(--gray-light);
  line-height: normal;
  font-size: 1rem;
}

div.MuiBox-root.privacy-policy-box-body-content td:not(:first-child) {
  padding-left: 12px;
}

div.MuiBox-root.privacy-policy-box-body-content td:not(:last-child) {
  padding-right: 12px;
}

div.MuiBox-root.privacy-policy-box-header {
  background: url("https://d3oo9a669kwmx7.cloudfront.net/backgrounds/home-main-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

div.MuiBox-root.dba-policy-box-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
