div.MuiListItemText-root.list-dune-list-item-text
  span.MuiTypography-root:has(div.MuiBox-root.list-dune-box-list-item-text) {
  width: 100%;
}

div.MuiListItemIcon-root.list-dune-list-item-icon {
  border-bottom: 2px solid var(--green);
  justify-content: center;
  margin-right: 20px;
  min-width: 0;
  padding: 0;
  height: 20px;
  align-items: center;
  width: 20px;
}

div.MuiListItemText-root.list-dune-list-item-text {
  align-items: center;
  display: flex;
}

li.MuiListItem-root.list-dune-list-item:not(:first-child) {
  padding-top: 30px;
}

span.MuiTypography-root.list-dune-text-list-item-icon {
  font-weight: 600;
}

span.MuiTypography-root.list-dune-text-list-item-text {
  font-weight: 400;
}
