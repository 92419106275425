.ciso-scroller {
  animation: scrollInfinite 12s linear infinite;
}

.ciso-item {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes scrollInfinite {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.ciso-scroll-section:hover .ciso-scroller {
  animation-play-state: paused;
}

.ciso-scroller:active {
  cursor: grabbing;
}
